@import '../../../../scss/theme-bootstrap';
$AkzidenzGroteskBQBol: 'AkzidenzGroteskBQBOL', $akzidenz;

.boutique-x5-page-formatter {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;
  .boutique-quickshop-media {
    &.boutique-quickshop-media__0 {
      .boutique-quickshop-media__copy {
        transform: none;
        top: 32%;
        .boutique-quickshop-media__quickshop-mobile {
          .product-brief__button-quickshop {
            margin-top: 0;
            top: 3.5px;
          }
        }
        &-container {
          &--content {
            p {
              @media #{$medium-up} {
                font-size: 1.97vw;
              }
              @media #{$boutiquelarge-up} {
                font-size: 30px;
              }
            }
          }
          &.boutique-quickshop-media__copy-container--one {
            margin-top: 20px;
            @media #{$medium-up} {
              margin-top: 15px;
            }
          }
          &.boutique-quickshop-media__copy-container--two {
            @media #{$medium-up} {
              margin-top: 30px;
            }
          }
          &.boutique-quickshop-media__copy-container--three {
            @media #{$medium-up} {
              margin-top: 18px;
            }
            .boutique-quickshop-media__copy-container--content {
              p {
                font-size: 36.4px;
                line-height: 1.1;
                @media #{$medium-up} {
                  font-size: 2.966vw;
                  line-height: 1;
                }
                @media #{$boutiquelarge-up} {
                  font-size: 42px;
                }
              }
            }
          }
        }
        &-belowcta {
          margin-top: 6%;
          @media #{$medium-up} {
            margin-top: 9%;
            margin-#{$ldirection}: -1px;
          }
          .boutique-quickshop-media__copy-container--content {
            p {
              font-size: 17.6px;
              @media #{$medium-up} {
                font-size: 1.26vw;
              }
              @media #{$boutiquelarge-up} {
                font-size: 17.3px;
              }
            }
          }
        }
      }
      .boutique-quickshop-media__quickshop-desktop {
        @media #{$medium-up} {
          margin-top: 7%;
          margin-#{$ldirection}: -3px;
        }
        .button {
          padding-#{$ldirection}: 16px;
          padding-#{$rdirection}: 16px;
        }
      }
    }
    &.boutique-quickshop-media__1 {
      margin-top: 9%;
      @media #{$medium-up} {
        margin-top: 2.7%;
      }
      .boutique-quickshop-media__copy {
        transform: none;
        top: 10%;
        padding-bottom: 30px;
        .boutique-quickshop-media__quickshop-mobile {
          .product-brief__button-quickshop {
            font-family: $akzidenz;
          }
        }
        @media #{$medium-up} {
          padding-bottom: 0;
        }
        &-abovecta {
          padding-top: 22px;
          @media #{$medium-up} {
            padding-top: 0;
          }
        }
        &-container {
          &.boutique-quickshop-media__copy-container--one,
          &.boutique-quickshop-media__copy-container--three,
          &.boutique-quickshop-media__copy-container--six,
          &.boutique-quickshop-media__copy-container--nine {
            @media screen and (min-width: $spp-pc-height) and (max-width: $medium-max) {
              margin-top: 0%;
            }
            .boutique-quickshop-media__copy-container--content {
              p {
                @media #{$medium-up} {
                  font-size: 1.596vw;
                }
                @media #{$boutiquelarge-up} {
                  font-size: 24px;
                }
              }
            }
          }
          &.boutique-quickshop-media__copy-container--two,
          &.boutique-quickshop-media__copy-container--four,
          &.boutique-quickshop-media__copy-container--five,
          &.boutique-quickshop-media__copy-container--seven,
          &.boutique-quickshop-media__copy-container--eight {
            @media #{$medium-down} {
              position: relative;
              .boutique-quickshop-media__copy-container--bullet {
                position: relative;
                #{$ldirection}: auto;
                display: inline-block;
                vertical-align: top;
              }
              .boutique-quickshop-media__copy-container--content {
                &.bullet-used {
                  position: relative;
                  #{$ldirection}: auto;
                  display: inline-block;
                  vertical-align: top;
                  width: auto;
                  text-align: center;
                }
              }
            }
            .boutique-quickshop-media__copy-container--content {
              p {
                font-family: $akzidenz;
                letter-spacing: 0.05em;
                @media #{$medium-up} {
                  font-size: 1.064vw;
                }
                @media #{$boutiquelarge-up} {
                  font-size: 16px;
                }
              }
            }
          }
          &.boutique-quickshop-media__copy-container--one {
            @media #{$medium-down} {
              margin: 0;
            }
          }
          &.boutique-quickshop-media__copy-container--two {
            @media #{$medium-down} {
              margin: 8px 0 5px 0;
              height: 20px;
            }
            @media #{$medium-up} {
              margin: 4.7% 0 0 0;
            }
          }
          &.boutique-quickshop-media__copy-container--three,
          &.boutique-quickshop-media__copy-container--four {
            @media #{$medium-down} {
              margin: 8px 0;
            }
          }
          &.boutique-quickshop-media__copy-container--four {
            @media #{$medium-down} {
              height: 6px;
            }
            @media #{$medium-up} {
              margin-top: 5%;
            }
          }
          &.boutique-quickshop-media__copy-container--five {
            @media #{$medium-down} {
              margin-top: 11px;
              margin-bottom: 0;
            }
            @media #{$medium-up} {
              margin-top: 1.2%;
            }
          }
          &.boutique-quickshop-media__copy-container--six {
            @media #{$medium-down} {
              margin-top: 6px;
              margin-bottom: 0;
            }
            @media #{$medium-up} {
              margin-top: 4%;
            }
          }
          &.boutique-quickshop-media__copy-container--seven {
            margin-top: 8px;
            @media #{$medium-up} {
              margin-top: 4.5%;
            }
          }
          &.boutique-quickshop-media__copy-container--eight {
            margin-top: 1.2%;
          }
          &.boutique-quickshop-media__copy-container--nine {
            margin-top: 10px;
            @media #{$medium-up} {
              margin-top: 4.5%;
            }
            @media screen and (min-width: $spp-pc-height) and (max-width: $medium-max) {
              margin-top: 0;
            }
          }
        }
        &-belowcta {
          margin-top: 5%;
          @media #{$medium-up} {
            margin-#{$ldirection}: 4px;
          }
          .boutique-quickshop-media__copy-container--content {
            p {
              font-family: $akzidenz;
              font-size: 11.5px;
              line-height: 1.4;
              letter-spacing: 0.05em;
              @media #{$medium-up} {
                font-size: 1.024vw;
                line-height: 1.65;
              }
              @media #{$boutiquelarge-up} {
                font-size: 14px;
              }
            }
          }
        }
      }
      .boutique-quickshop-media__quickshop-desktop {
        @media #{$medium-up} {
          padding-#{$ldirection}: 4px;
          margin-top: 9%;
          .button {
            padding: 0 16px;
          }
        }
        @media screen and (min-width: $spp-pc-height) and (max-width: $medium-max) {
          margin-top: 3%;
        }
      }
      .play-button-icon {
        &.play-icon--bottom {
          @media #{$medium-only} {
            width: 5%;
          }
          @media #{$medium-up} {
            bottom: 3%;
          }
        }
      }
    }
  }
  .boutique-x5-mod3 {
    text-align: center;
    margin-top: 7.7%;
    @media #{$medium-up} {
      margin-top: 2.7%;
    }
    .content-block-video {
      @media #{$medium-down} {
        padding: 0 4%;
      }
      @media #{$medium-up} {
        display: inline-block;
        margin: 0 10px;
        width: 45%;
        max-width: 610px;
        .content-block-tout--content-over-image {
          width: 100%;
          .play-icon {
            width: 5.988vw;
            max-width: 90px;
          }
        }
      }
      .content-block-tout {
        &__line {
          p {
            letter-spacing: -0.01em;
            font-weight: 400;
            text-transform: initial;
            margin: 0;
            @media #{$medium-down} {
              font-size: 19px;
              text-align: #{$ldirection};
              font-family: $optimabook;
            }
            @media #{$medium-up} {
              font-family: $optimalight;
              font-size: 3.326vw;
            }
            @media #{$boutiquelarge-up} {
              font-size: 50px;
            }
          }
        }
      }
    }
    .basic-carousel-formatter {
      .slick-slider {
        .slick-next,
        .slick-prev {
          &.hide {
            display: none !important; // Slick carousel adds display properties inline so need to override.
          }
        }
      }
    }
  }
  .skinrhythm-skindamage {
    @media #{$medium-down} {
      margin-top: 1.4%;
    }
    &__header {
      font-family: $optimalight;
      font-size: 32px;
      letter-spacing: 0;
      @media #{$medium-up} {
        font-size: 50px;
      }
    }
    &__breadcrumbs {
      p {
        font-family: $akzidenzBQ;
        letter-spacing: 0.05em;
      }
    }
    .youtube-preview-player__container-overlay {
      @media #{$medium-up} {
        letter-spacing: 0;
        font-size: 2vw;
      }
      @media #{$boutiquelarge-up} {
        font-size: 27px;
      }
    }
    .basic-carousel-formatter {
      &__carousel {
        &.slick-slider {
          .slick-prev,
          .slick-next {
            width: 30px;
            height: 110px;
            z-index: 10;
            background-image: none !important; // required to override inline CSS from JS; temp fix
            background: rgba($color-white, 0.45);
            &:before {
              content: '';
              width: 32px;
              height: 32px;
              display: block;
              background: $color-white;
              position: relative;
            }
            @media #{$medium-up} {
              width: 50px;
            }
          }
          .slick-prev {
            #{$ldirection}: 0;
            &:before {
              @include svg-icon-inline-mask('arrow-left.svg');
              #{$ldirection}: -2px;
              @media #{$medium-up} {
                #{$ldirection}: 4px;
              }
            }
          }
          .slick-next {
            #{$rdirection}: 0;
            &:before {
              @include svg-icon-inline-mask('arrow-right.svg');
              #{$rdirection}: -1px;
              @media #{$medium-up} {
                #{$rdirection}: -10px;
              }
            }
          }
        }
      }
    }
  }
  .boutique-x5-mod6 {
    margin-top: 18%;
    @media #{$medium-up} {
      margin-top: 7%;
    }
    .content-block-video {
      .play-icon {
        &.play-icon--bottom {
          @media #{$medium-down} {
            bottom: 10px;
          }
        }
      }
    }
    .content-block-tout {
      &__inner {
        position: relative;
      }
      &__text {
        position: absolute;
        top: -34%;
        width: 100%;
        text-align: center;
        @media #{$medium-up} {
          top: -17%;
        }
      }
      &__line {
        p {
          font-family: $optimalight;
          letter-spacing: -0.01em;
          font-size: 32px;
          @media #{$medium-up} {
            font-size: 3.326vw;
          }
          @media #{$boutiquelarge-up} {
            font-size: 50px;
          }
        }
      }
    }
  }
  .basic-carousel-formatter {
    &__carousel {
      &.slick-slider {
        ul.slick-dots {
          display: block;
          position: relative;
          bottom: auto;
          margin-top: 2.9%;
          @media #{$medium-up} {
            margin-top: 1.7%;
          }
          li {
            margin: 0 1px;
            @media #{$medium-up} {
              margin: 0 4.5px;
            }
            &.slick-active {
              button {
                width: 0;
                &:before {
                  color: $color-navy;
                  font-size: 7px;
                  line-height: 7px;
                  border: 0;
                  @media #{$medium-up} {
                    font-size: 11px;
                    line-height: 11px;
                  }
                }
              }
            }
            button:before {
              border: 1px $color-navy solid;
              width: 6px;
              height: 6px;
              @media #{$medium-up} {
                width: 9px;
                height: 9px;
              }
            }
          }
        }
        .slick-prev,
        .slick-next {
          width: 27px;
          height: 57px;
          @media #{$medium-down} {
            top: 26%;
          }
          @media #{$medium-up} {
            width: 20px;
            height: 35px;
          }
        }
      }
    }
  }
  .boutique-x5-mod4 {
    .youtube-preview-player__container-overlay {
      @media #{$medium-down} {
        letter-spacing: 0.1em;
      }
    }
  }
  .boutique-x5-mod5 {
    @media #{$medium-down} {
      margin-top: 12px;
    }
    .basic-carousel-formatter {
      .content-block-video {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
      }
      &__header {
        .content-block__typography {
          p {
            font-family: $optimalight;
            font-size: 32px;
            letter-spacing: -0.01em;
            margin: 3.1% 0 3%;
            @media #{$medium-up} {
              font-size: 3.514vw;
              letter-spacing: 0;
              font-family: $optimalight;
            }
            @media #{$boutiquelarge-up} {
              font-size: 48px;
            }
          }
        }
      }
      &__carousel {
        @media #{$medium-down} {
          margin-top: 12px;
        }
        .content-block-tout {
          &__line {
            p {
              font-size: 17px;
              font-family: $optimabook;
              text-align: center;
              letter-spacing: -0.01em;
              @media #{$medium-up} {
                position: absolute;
                #{$ldirection}: 50%;
                top: 28%;
                transform: translateX(-50%);
                font-family: $optimalight;
                letter-spacing: -0.02em;
                font-size: 3.326vw;
                text-align: center;
                width: 90%;
              }
              @media #{$boutiquelarge-up} {
                font-size: 50px;
              }
            }
          }
        }
        .content-block-video {
          .play-icon {
            top: 56%;
          }
        }
      }
    }
  }
  .boutique-x5-mod7 {
    .content-block__eyebrow {
      p {
        @media #{$medium-down} {
          font-family: $optimalight;
          font-size: 32px;
          letter-spacing: -0.01em;
          margin: 33px 0 0 0;
        }
      }
      p + p {
        @media #{$medium-down} {
          margin: 11px 0 0 0;
        }
      }
    }
    .boutique-skin-rhythm__products-hero-desktop-products-container
      .boutique-skin-rhythm__products-hero-product-text {
      @media #{$medium-up} {
        letter-spacing: 0.15em;
      }
    }
    .boutique-skin-rhythm__products-quick-shop-panel-main-container-1,
    .boutique-skin-rhythm__products-quick-shop-panel-main-container-2,
    .boutique-skin-rhythm__products-quick-shop-panel-main-container-3 {
      .boutique-skin-rhythm__products-quick-shop-panel-content-container {
        .boutique-skin-rhythm__products-quick-shop-panel-content {
          .boutique-skin-rhythm__products-quick-shop-panel-content-right {
            .boutique-skin-rhythm__products-quick-shop-panel-content-switch-left {
              #{$ldirection}: 0.75vw;
            }
          }
        }
      }
    }
    .basic-shopnow-product__container {
      & > img {
        width: 100%;
      }
      .basic-shopnow-product__content-title {
        margin-top: 7.2%;
      }
    }
    .basic-carousel-formatter__carousel {
      &.slick-slider {
        ul {
          &.slick-dots {
            @media #{$medium-down} {
              bottom: 75px;
              li {
                width: 15px;
                height: 15px;
                button:before {
                  border-color: $color-white;
                }
                &.slick-active {
                  button:before {
                    color: $color-white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .skinrhythm-products {
    margin-top: 2.5%;
  }
  @media #{$medium-up} {
    .sp19-ppro__mod4 {
      margin-top: 3%;
      text-align: #{$ldirection};
      &-copy {
        #{$ldirection}: auto;
        #{$rdirection}: 11.5%;
        top: 19.5%;
        .el_boutique_h2_lg {
          font-size: 4.148vw;
          margin-bottom: 5%;
          @media #{$boutiquelarge-up} {
            font-size: 56px;
          }
        }
        .el_boutique_h3 {
          font-family: $akzidenz;
          font-size: 14px;
          letter-spacing: 0.05em;
          line-height: 1.4;
        }
        .sp19-ppro__dlbtn {
          max-width: 217px;
          margin-top: 4.5%;
          padding: 0;
        }
        .button--dark.button--secondary.sp19-ppro__storebtn {
          background: none;
          border: none;
          text-align: #{$ldirection};
          padding: 0;
          text-decoration: underline;
          margin-top: 3%;
        }
      }
      .el_boutique_dark {
        color: $color-white;
        &.free-in-store {
          font-weight: 600;
          font-family: $akzidenzBQ;
          font-size: 12px;
          letter-spacing: 0.2em;
        }
        &.instructions {
          line-height: 1.7;
          font-family: $akzidenzBQ;
          font-size: 14px;
        }
      }
    }
  }
  @media #{$medium-down} {
    .sp19-ppro__mod4-copy {
      position: relative;
      top: 0;
      .el_boutique_h2_lg {
        margin-top: 14px;
        letter-spacing: -0.01em;
      }
      .sp19-ppro__service-block {
        &.free-in-store {
          font-family: $akzidenz;
          letter-spacing: 0.15em;
          font-size: 15px;
          font-weight: 600;
          margin-top: 8px;
        }
        &.instructions {
          letter-spacing: 0.05em;
          font-size: 14px;
          line-height: 1.4;
        }
      }
      .sp19-ppro__storebtn {
        background: $color-white;
        color: $color-navy;
        text-decoration: underline;
        margin-top: 1vw;
      }
      .sp19-ppro__dlbtn {
        margin-top: 4vw;
      }
      .el_boutique_caveat {
        letter-spacing: 0.05em;
        margin-top: 0;
        font-size: 11px;
      }
    }
  }
  .olapic-widget {
    @media #{$medium-up} {
      padding: 0;
      margin-top: 2.7%;
    }
  }
}
